import React from "react";
import DOBModal from "./DOBModal";
import ErrorModal from "./ErrorModal";
import QuestionsModal from "./QuestionsModal";
import URLS from "../../shared/constants/urls";
import Modal from "../../shared/components/Modal";
import moment from "moment";
import MammService from "./services/MammService";
import noticeIcon from "../../shared/assets/info.svg";
import { MAMM_DOB_PROMPT, MAMM_DOB_TEXT } from "../../shared/constants/messages";
import NoticeModal from "./NoticeModal";
import "./MammQuestionnaire.scss";
import TimesDrawer from "../../Search/components/DynamicDrawers/TimesDrawer";
import { connect } from "react-redux";

class QuestionnaireModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 0,
      error: null,
      visitType: window.sessionStorage.getItem("VisitTypeID"),
      notice: null,
      service: null,
      timeDrawerSubHeader: "",
    };
    this.today = moment(new Date());
    this.inner = this.inner.bind(this);
    this.birthSubmission = this.birthSubmission.bind(this);
    this.submissionHandler = this.submissionHandler.bind(this);
    this.handleStepChange = this.handleStepChange.bind(this);
    this.momentDateStringFormat = "MM-DD-YYYY";
    this.isSuperMammoScheduling = window.sessionStorage.getItem("isSuperMammoScheduling") || false;
    this.handleCannotSchedule = this.handleCannotSchedule.bind(this);
  }

  componentDidMount() {
    this.setState({ step: !this.state.visitType || this.isSuperMammoScheduling ? 1 : 0 });
  }

  componentDidUpdate(prevState) {
    if (prevState.step !== this.state.step) {
      document.activeElement.blur();
    }
  }

  handleCannotSchedule() {
    this.props.dispatch({
      type: 'SET_CAN_SCHEDULE_ONLINE',
      value: false,
    });
  };

  birthSubmission(date) {
    const currentAgeInMonths = this.today.diff(moment(date, this.momentDateStringFormat), "months");
    let age = this.today.diff(moment(date, "MM-DD-YYYY"), "months");

    if (age < 479) {
      this.handleCannotSchedule()
      this.setState({
        error: {
          icon: noticeIcon,
          text: "You must be at least 40 years of age to schedule your mammogram online. Please contact your provider.",
          url: URLS.findADoctorUrl,
          urlTitle: "Find a Provider",
          title: this.isSuperMammoScheduling ? "Contact your provider" : null,
        },
      });
    } else if (age < 479) {
      this.setState({
        notice: {
          icon: noticeIcon,
        },
      });
      window.sessionStorage.setItem("qDate", moment(date, this.momentDateStringFormat).format(this.momentDateStringFormat));
      this.setState({ step: 2 });
    } else {
      window.sessionStorage.setItem("qDate", moment(date, this.momentDateStringFormat).format(this.momentDateStringFormat));
      this.setState({ step: 3, service: MammService(Math.floor(currentAgeInMonths / 12)) });
    }
  }

  handleStepChange(newStep) {
    let date = window.sessionStorage.getItem("qDate");
    let age = this.today.diff(moment(date, "MM-DD-YYYY"), "months");
    this.setState({ step: newStep, service: MammService(Math.floor(age / 12)) });
  }

  submissionHandler(action) {
    if (action && action.error) {
      action.error.icon = noticeIcon;
      this.setState({ error: action.error });
    } else {
      const showScheduleTime = action.quesAnswers.find((x) => x.answer == "yes");
      const today = moment();
      const patientDob = moment(window.sessionStorage.getItem("qDate"));
      const diffYears = today.diff(patientDob, "years");
      const lastDob = patientDob.clone().add(diffYears, "years");
      const diffMonths = today.diff(lastDob, "months");

      const patientAppointmentDate = moment(window.sessionStorage.getItem("filter-date"));
      const checkApptDateAge = patientAppointmentDate.diff(patientDob, "years");

      if (showScheduleTime) {
        this.props.setVisitTypeId(window.sessionStorage.getItem("VisitTypeID"));
        if (diffYears != 39 && diffMonths != 11) {
          this.setState({
            timeDrawerSubHeader: `Based on your answers, an hour is needed to complete your annual mammogram. 
            To schedule this important preventive screening, please select a new appointment time.`,
          });
        } else {
          this.setState({
            timeDrawerSubHeader: `Beginning at age 40, you become eligible for an annual mammogram. Based on your answers, 
            an hour is needed to complete your annual mammogram. To schedule this important preventive screening, 
            please select a new appointment time.`,
          });
        }
        this.setState({ step: 4 });
      } else if (!showScheduleTime && checkApptDateAge != 40 && diffYears == 39 && diffMonths == 11) {
        this.setState({
          timeDrawerSubHeader: `Beginning at age 40, you become eligible for an annual mammogram. To schedule this important preventive screening, 
           please select a new appointment time.`,
        });
        window.sessionStorage.setItem("isShowScheduleTime", true);
        this.setState({ step: 4 });
      } else {
        let existing = window.sessionStorage.getItem("VisitTypeID");
        if (existing) {
          this.setState({ step: 0 });
          if (this.isSuperMammoScheduling) {
            this.props.redirect();
          }
        } else {
          this.setState({
            error: {
              icon: noticeIcon,
              text: "An error occurred. Please refresh the page and try again.",
            },
          });
        }
      }
    }
  }

  inner() {
    if (!this.state.error) {
      switch (this.state.step) {
        case 1:
          return (
            <DOBModal
              prompt={MAMM_DOB_PROMPT}
              title={"Let's Get Started"}
              text={MAMM_DOB_TEXT}
              submissionHandler={this.birthSubmission}
            />
          );
        case 2:
          return (
            <NoticeModal
              notice={this.state.notice}
              onStepChange={this.handleStepChange}
            />
          );
        case 3:
          return (
            <QuestionsModal
              title={"Pre-qualifying Questions"}
              service={this.state.service}
              submissionHandler={this.submissionHandler}
              flow='mam'
            />
          );
        case 4:
          return (
            <TimesDrawer
              show={true}
              flow={"mam"}
              handleClose={() => {}}
              handleCloseAll={() => {}}
              onAnimationEnd={() => {}}
              onMamTimeClick={(date, time, isSchedule) => {
                this.props.updateSchedulingTime(date, time);
                this.props.redirect();
              }}
              mamLocation={{ Id: this.props.location.departmentId, ...this.props.location }}
              isSchedule={false}
              headerContent={"Please select a later date"}
              subHeaderContent={this.state.timeDrawerSubHeader}
              isModifySchedule={true}
            />
          );
        default:
          return <div />;
      }
    } else {
      this.handleCannotSchedule();
      return <ErrorModal error={this.state.error} />;
    }
  }

  render() {
    return this.isSuperMammoScheduling ? (
      <div className='super-mammo-schedule'>{this.inner()}</div>
    ) : (
      <Modal
        hideFunction={() => {
          window.location.href = `${URLS.gatewayPageUrl}`;
        }}
        showModal={this.state.step > 0 || this.state.error}>
        {this.inner()}
      </Modal>
    );
  }
}

export default connect()(QuestionnaireModal);